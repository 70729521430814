/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "fontsource-montserrat/900.css";
import "fontsource-montserrat/800.css";
import "fontsource-montserrat/700.css";
import "fontsource-montserrat/600.css";
import "fontsource-montserrat/500.css";
import "fontsource-montserrat/400.css";
import "@fontsource/azeret-mono";

import "./src/css/index.css";
