module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SpaceDelta","short_name":"SpaceDelta","start_url":"/","background_color":"#100E15","theme_color":"#2A1264","display":"minimal-ui","icon":"src/images/glyph.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3c0ce81e156b3246770b0bbc0ce92f9a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.spacedelta.net"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
